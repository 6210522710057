import axios from "axios";
import { usePermissions } from "react-admin";

const useImpersonate = () => {
    const { isPending, permissions } = usePermissions();
    const canImpersonate = permissions?.includes("admin");

  const impersonate = async (user_id: number) => {
    try {
      if (!canImpersonate) return false;

      if (isImpersonated()) {
        stopImpersonate(false);
      }

      const impersonatedUser = await axios
        .post(`/auth/impersonate`, {
          user_id,
        })
        .then((response) => response.data);

      const impersonator = {
        me: JSON.parse(localStorage.getItem("me") || "null"),
        organization: JSON.parse(
          localStorage.getItem("organization") || "null"
        ),
        roles: JSON.parse(localStorage.getItem("roles") || "null"),
        token: JSON.parse(localStorage.getItem("token") || "null"),
      };

      localStorage.setItem("impersonator", JSON.stringify(impersonator));

      const { access_token, roles, user, organization } = impersonatedUser;

      localStorage.setItem("token", JSON.stringify({ access_token }));
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("me", JSON.stringify(user));
      localStorage.setItem("organization", JSON.stringify(organization));

      window.location.reload();

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const getImpersonator = () => JSON.parse(localStorage.getItem("impersonator") || "null");

  const stopImpersonate: (reloadWindow?: boolean) => boolean = (
    reloadWindow = true
  ) => {
    try {
      const { me, organization, roles, token } = getImpersonator();
      localStorage.setItem("me", JSON.stringify(me));
      localStorage.setItem("organization", JSON.stringify(organization));
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.removeItem("impersonator");

      if (reloadWindow) {
        window.location.reload();
      }

      return true;
    } catch (e) {
      console.log("Error stopping impersonation", e);
      return false;
    }
  };

  const isImpersonated = () => localStorage.getItem("impersonator") !== null;

  return {
    impersonate,
    stopImpersonate,
    isImpersonated,
    isPending,
    getImpersonator,
    canImpersonate,
  };
};

export default useImpersonate;
