/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-useless-escape */
import en from "ra-language-english";

export default {
  ...en,
  login: {
    email: "Email",
    password: "Password",
    sign_in: "Sign in",
    forgot_password: "Forgot password?",
    remember_me: "Remember me",
    email_required: "Email is required",
    password_required: "Password is required",
    logout: "Logout",
  },
  welcome_to: "Welcome to",
  plateform_name: "UniVR Manager",
  resources: {
    dashboard: "Dashboard",
    experiences: "Experiences",
    users: "Users",
    licenses: "Licenses",
    tags: "Tags",
    organizations: "Organizations",
    "experience-groups": "Experience Groups",
    create: "Create",
    profile: "Profile",
    my_account: "My account",
    my_organization: "My organization",
    my_users: "My users",
    my_experiences: "My experiences",
    "service-licenses": "Service licenses",
    devices: "Devices",
  },
  dashboard: {
    sessions_duration: "Total sessions duration",
    utilizations: "Utilizations",
    analytics: "Explore your data analytics",
    external_link: "Discover",
    most_used_experiences: "Most used applications",
    monthly_utilizations: "Monthly utilizations",
    number_of_utilizations: "Number of utilizations",
    empty_top_list: "No experience has been added yet.",
    news: "News",
  },
  misc: {
    last_updates: "Last updates",
    total: "Total",
    see_more: "See more",
    no_data: "No data",
    name: "Name",
    edit: "Edit",
    search: "Search",
    options: "Options",
    upload_image_helper:
      "Accepted formats: .jpg, .jpeg, .png | Maximum size: 5Mb | Maximum dimensions: 1920x1080px",
    general_informations: "General informations",
    start_date: "Start date",
    end_date: "End date",
    specific_users: "Specific users",
    id: "ID",
    last_update: "Last update",
    publish: "Publish",
    next: "Next",
    back: "Back",
    seventy_characters_max: "70 characters maximum",
    description: "Description",
    image: "Image",
    zip_file: "Zip file",
    new_zip_file: "New zip file",
    archive_exe_video: "Archive containing the executable or the 360 video.",
    video_formats_supported:
      "Supported video formats: .mp4, .mov, .webm, .avi, .mkv, .wmv, .flv, .mpeg-2, .gifv.",
    url: "URL",
    share_options: "Share options",
    share_options_helper:
      "I acknowledge that I have read and accepted UniVRsity's Terms of Use, and have the necessary rights to share this application.",
    type: "Type",
    build_helper:
      "Check the <a\n" +
      'href=https://univr-solutions.notion.site/UniVR-MANAGER-d82a993e4dcd4b3e8749e2522bd8ecc1" target="_blank" rel="noopener noreferrer">documentation</a> for more information.',
    email_domain: "Email domain",
    battery: "Battery",
    last_seen: "Last seen",
    action: "Action",
    parameters: "Parameters",
    status: "Status",
    close: "Close",
    cancel: "Cancel",
    install: "Install",
    delete: "Delete",
    yes: "Yes",
    no: "No",
    coming_soon: "Coming soon...",
    welcome: "Welcome!",
    logo: "Logo",
    undefined: "Undefined",
    learn_more: "Learn more",
    read: "Read",
    accept: "I accept",
    logo_format: "Recommended size: 140x140px",
    no_valid_color: "No valid color found in thumbnail",
    use_color: "Set a color from the thumbnail",
    invalid_reference_url: "Invalid link to the TSV file",
    reference_url_helper: "Link to a TSV file containing references",
    disable: "Disable",
    disable_confirm_content: "Do you really want to disable this item?",
    disable_success: "Item successfully disabled!",
    signup_enabled: "Self-registration",
    see_site_as: "You see the site as",
    stop: "Stop",
    impersonate: "Impersonate",
  },
  profile: {
    title: "Profile",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    organization: "Organization",
    roles: "Roles",
    password: "Password",
    save: "Save",
    sites: "Sites",
    update_success: "Profile updated successfully",
    cgu_shortcut: "Terms of Use",
    cgu_accepted_on: "Accepted on",
    view_cgu: "View Terms of Use",
    cgu_title: "Terms of Use",
    cgu_content: "Content of the Terms of Use",
    cgu_accept_label:
      "I acknowledge that I have read and accepted UniVR's Terms of Use.",
    cgu_success: "Terms of Use accepted successfully",
    duplicate_error: "The terms of use have already been accepted.",
  },
  organizations: {
    create: "Create organization",
    name: "Organization name",
    singular_name: "Organization",
    not_found: "No organization found",
    experiences: {
      images: {
        default: "Experience default image",
      },
    },
    list: "Organizations list",
    list_helper: "with which you want to share this experience.",
    "service-license-status": "License status",
    univrsity: "UniVRsity",
  },
  tags: {
    categories: {
      index: "Categories",
      create: "Create category",
    },
    editors: {
      index: "Editors",
      create: "Create editor",
      not_found: "No editor found",
    },
    headsets: {
      index: "Headsets",
      create: "Create headset",
      not_found: "No headset found",
    },
    tags: {
      index: "Tags",
      create: "Create tag",
      not_found: "No tag found",
    },
  },
  licenses: {
    create: "Create license",
  },
  notifications: {
    title: "Expiring licenses",
    none: "No notification",
  },
  "experience-groups": {
    name: "Experience groups",
    singular_name: "Experience group",
    create: "Create experience group",
  },
  experiences: {
    singular_name: "Experience",
    create: "Add experience",
    delete_confirm: "Are you sure you want to delete this experience?",
    new_version: "New version",
    first_version: "First version",
    steps: {
      general: "General",
      technical: "Technical",
      share: "Share",
      advanced_interface: "Advanced interface",
      simplified_interface: "Simplified interface",
    },
    experience_title: "Experience title",
    types: {
      index: "Experience's type",
      build: "Developed experience (File .exe, .apk)",
      video: "360° video",
      browser: "URL to web experience",
      oculus: "Path to Oculus PC application",
      package: "Meta Quest Store application",
      installer: "Experience with installer",
    },
    installed_software_name: "Installed software name",
    package_name: "Package name",
    meta_quest_store_package_name: "Meta Quest Store package name",
    exectuable_name: "Executable name",
    installer_name: "Installer name",
    installer_help_text:
      "Name of the executable to launch, must end with .exe or .apk.",
    version: {
      initial: "Initial version",
      description: "Update note",
      new_version: "New version",
    },
    shared_univrsity: "Shared with UniVRsity",
    duplicate: "Duplicate",
    duplicate_success: "Experience duplicated successfully!",
    duplicate_error: "Error duplicating experience.",
  },
  dates: {
    duration: "Duration",
    "1_month": "1 month",
    "3_months": "3 months",
    "6_months": "6 months",
    "1_year": "1 year",
    "3_years": "3 years",
    unlimited: "Unlimited",
    custom: "Custom",
    same_as_service_license: "Same as service license",
    creation_date: "Creation date",
  },
  users: {
    create: "Create user",
    roles: "Roles",
    change_password_question: "Change password?",
    user: "User",
  },
  "service-licenses": {
    types: {
      paid: "Classic",
      trial: "Trial",
      unlimited: "Unlimited",
      trial_helper:
        "The trial license allows you to test the service for a limited time.",
    },
    services: {
      index: "Services",
      home: "Home",
      manager: "Manager",
      analytics: "Analytics",
    },
    create: "Create service license",
    status: "Status",
  },
  status: {
    index: "Status",
    active: "Active",
    expiring: "Expiring",
    expired: "Expired",
    undefined: "Undefined",
  },
  devices: {
    create: "Create device",
    actions: {
      title: "Actions",
      action_dialog: {
        title: "Pending actions on the device",
        banner: {
          done: "%{count} done",
          errors: "%{count} errors",
          total: "%{count} total",
          delete: "Clear done actions",
        },
      },
      install_apps_dialog: {
        menu_item: "Install applications",
        title: "Install applications on devices",
      },
      shutdown: "Shutdown",
      reboot: "Reboot",
      install: "Install",
      delete_dialog: {
        title: "Confirm device deletion",
        confirmation: "Are you sure you want to delete the devices?",
        information: "You will still be able to re-pair them via UniVR Home.",
      },
      status: {
        pending: "Pending",
        done: "Done",
        error: "Error",
        progress: "In progress",
        undefined: "Undefined",
      },
    },
  },
  roles: {
    admin: "Administrator",
    adminOrga: "Organization administrator",
    normal: "User",
    contentCreator: "Content creator",
  },
  navigation: {
    show: "Details",
  },
};
