import { useGetIdentity, useTranslate } from "react-admin";
import useImpersonate from "../../../hooks/useImpersonate";
import { Button, Paper, Stack, Typography } from "@mui/material";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";

const ImpersonateBanner = () => {
  const { stopImpersonate, isImpersonated } = useImpersonate();
  const { data: user, isLoading } = useGetIdentity();
  const translate = useTranslate();

  if (!isImpersonated() || isLoading) return null;

  const userFullName = `${user?.first_name || ""} ${user?.last_name || ""}`;
  const organizationName = user?.organization?.name || "";

  return (
    <Stack direction='column' justifyContent='center' alignItems='center'>
      <Paper sx={{ p: 1, mb: 2, bgcolor: "#007bff12" }} variant='outlined'>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          gap={10}
        >
          <Stack direction='row' gap={1} alignItems='center'>
            <SwitchAccountIcon color='info' />
            <Typography variant='body2' color='#797979'>
              {translate("misc.see_site_as")} <strong>{userFullName}</strong> -{" "}
              {translate("profile.organization")}{" "}
              <strong>{organizationName}</strong>
            </Typography>
          </Stack>
          <Button
            variant='outlined'
            color='info'
            onClick={() => stopImpersonate()}
            size='small'
          >
            {translate("misc.stop")} {/* Updated to use translation */}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ImpersonateBanner;
