import { Edit } from "react-admin";
import OrganizationForm from "./OrganizationForm";
import { uploadFile } from "../../services/uploader-service";

export const transformOrganizationForm = async (data: any) => {
  if (data.default_experience_image instanceof File) {
    data.default_experience_image = await uploadFile(
      data.default_experience_image,
      () => {}
    );
  } else if (data.default_experience_image === null) {
    data.default_experience_image = null;
  }
  
  if (data.logo instanceof File) {
    data.logo_id = (await uploadFile(data.logo, () => {})).id;
  } else if (data.logo === null) {
    data.logo_id = null;
  }
  delete data.logo; // unset logo to avoid error

  return data;
};

const OrganizationEdit = () => (
  <Edit
    transform={transformOrganizationForm}
    actions={null}
    sx={{
      "& .RaEdit-card": {
        background: "none",
        boxShadow: "none",
        border: "none",
      },
    }}
    redirect='show'
    mutationMode='pessimistic'
  >
    <OrganizationForm />
  </Edit>
);

export default OrganizationEdit;
