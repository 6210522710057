import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { CustomPagination } from "../Layout/CustomPagination";
import CustomActionBar from "../Layout/CustomActionBar";
import { useEffect, useState } from "react";
import SkeletonList from "../Loading/SkeletonList";
import ListLayout from "../Layout/ListLayout";
import DatagridTitleField from "../Layout/Fields/DatagridTitleField";

const TagFilter = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput
        label={translate("misc.type")}
        variant='outlined'
        placeholder='Type'
        source='type'
        alwaysOn
      />
      <TextInput
        label={translate("resoruces.name")}
        variant='outlined'
        source='name'
      />
    </Filter>
  );
};

const TagList = (props) => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const translate = useTranslate();

  useEffect(() => {
    if (!organization || !permissions) setLoading(true);
    else setLoading(false);
  }, [organization, permissions]);

  if (!hasAccess({ name: "tags", crudType: "list" }, permissions))
    redirect("/");

  return (
    <ListLayout title={translate("resources.tags")}>
      {!loading ? (
        <List
          exporter={false}
          perPage={50}
          pagination={<CustomPagination />}
          filters={<TagFilter permissions={permissions} />}
          filterDefaultValues={
            !permissions.includes("admin")
              ? { organization: organization.name }
              : null
          }
          actions={
            <CustomActionBar
              createButtonProps={{ label: translate("tags.tags.create") }}
            />
          }
        >
          <Datagrid rowClick='edit' bulkActionButtons={false}>
            <DatagridTitleField source='name' label={translate("misc.name")} />
            <TextField
              source='type_display_name'
              label={translate("misc.type")}
            />
            <DateField
              source='updated_at'
              label={translate("misc.last_update")}
              showTime
            />
            <EditButton variant='contained' icon={null} />
          </Datagrid>
        </List>
      ) : (
        <SkeletonList />
      )}
    </ListLayout>
  );
};

export default TagList;
