import {DeleteButton, DeleteWithConfirmButton, SaveButton, Toolbar, ToolbarProps, usePermissions, useRecordContext, useTranslate} from "react-admin";
import React from "react";
import {Stack} from "@mui/material";

interface CustomToolbarProps extends ToolbarProps {
    deleteRedirect?: string;
}

const CustomSimpleToolbar: React.FC<CustomToolbarProps> = ({deleteRedirect, ...props}) => {
    const record = useRecordContext();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const isAdmin = permissions?.includes("admin");

    return (
      <Toolbar {...props}>
        <Stack
          direction='row'
          gap={2}
          justifyContent={record ? "space-between" : "flex-end"}
          py={2}
          width={"100%"}
        >
          {record && (
            <DeleteWithConfirmButton
              size={"medium"}
              icon={null}
              color={"error"}
              confirmColor='warning'
              label={translate("misc.disable")}
              redirect={deleteRedirect}
              mutationMode={"pessimistic"}
              disabled={!isAdmin}
              translateOptions={{ name: record.name }}
              confirmContent={translate("misc.disable_confirm_content")}
              successMessage={translate("misc.disable_success")}
              
            />
          )}
          <SaveButton
            icon={null}
            variant={"contained"}
            size={"medium"}
            alwaysEnable
          />
        </Stack>
      </Toolbar>
    );
};

export default CustomSimpleToolbar;