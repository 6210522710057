import { useRecordContext, useTranslate } from "react-admin";
import useImpersonate from "../../../hooks/useImpersonate";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { IconButton, Tooltip } from "@mui/material";

const ImpersonateButton = () => {
  const { impersonate, isPending, canImpersonate } = useImpersonate();
  const record = useRecordContext();
  const translate = useTranslate();

  const onClick = () => {
    impersonate(record.id as number);
  };

  if (!canImpersonate) return null;

  return (
    <Tooltip title={translate("misc.impersonate")} placement='top'>
      <IconButton
        onClick={onClick}
        disabled={isPending}
        aria-label='impersonate'
      >
        <SwitchAccountIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ImpersonateButton;
