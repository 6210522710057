import { TextField, TextFieldProps } from "react-admin";
import { theme } from "../../../global/styles";

const DatagridTitleField: React.FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      sx={{
        ...props.sx,
        color: theme.palette.primary.darker,
        fontWeight: 600
      }}
    />
  );
};

export default DatagridTitleField;
