import { useEffect, useState } from "react";
import {
    AutocompleteInput,
    ChipField,
    PasswordInput,
    ReferenceInput,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    usePermissions,
    useRecordContext,
    useRedirect,
    useTranslate,
} from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { hasAccess } from "../../global/permissions";
import { IRole } from "../../models/role";
import { useParams } from "react-router-dom";
import { Container, FormControlLabel, Switch } from "@mui/material";
import CustomSimpleToolbar from "../Layout/CustomSimpleToolbar";
import SkeletonForm from "../Loading/SkeletonForm";

interface IOrganizationInput {
  id: number;
  name: string;
}

interface UserFormProps {
  redirectUrl?: string;
}

const UserForm: React.FC<UserFormProps> = ({ redirectUrl }) => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [organizations, setOrganizations] = useState<IOrganizationInput[]>([]);
  const userOrga = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const [showPassowordInput, setShowPasswordInput] = useState<boolean>(
    record?.id ? false : true
  );
  const firstTime = record?.id ? false : true;
  const translate = useTranslate();
  const isAdmin = permissions.includes("admin");

  useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data }) => {
          let roles = data;
          if (!permissions.includes("admin"))
            roles = roles.filter((r) => r.id !== "admin");
          setRoles(roles);
        })
        .catch((err) => console.error(err));

    // organizations
    if (userOrga && organizations.length === 0) {
      dataProvider
        .getList("organizations")
        .then(({ data }) => {
          let result = data.map((o) => ({ id: o.id, name: o.name }));

          if (!permissions.includes("admin"))
            result = data.filter((o) => o.id === userOrga.id);
          result.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          );
          setOrganizations(result);
        })
        .catch((err) => console.error(err));
    }
  }, [userOrga, organizations.length, permissions, roles.length]);

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors["first_name"] = translate("ra.validation.required");
    }

    if (!values.last_name) {
      errors["last_name"] = translate("ra.validation.required");
    }

    if (!values.email) {
      errors["email"] = translate("ra.validation.required");
    }

    if (!values.organization_id) {
      errors["organization_id"] = translate("ra.validation.required");
    }

    if (!values.roles || values.roles.length === 0) {
      errors["roles"] = translate("ra.validation.required");
    }

    if (!values.password && firstTime) {
      errors["password"] = translate("ra.validation.required");
    }

    return errors;
  };

  if (
    roles.length === 0 ||
    organizations.length === 0 ||
    !userOrga ||
    !permissions
  )
    return (
      <div>
        <SkeletonForm />
      </div>
    );

  if (!hasAccess({ name: "users", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <Container maxWidth={"sm"}>
      <SimpleForm
        validate={validateForm}
        defaultValues={{ name: "default" }}
        toolbar={<CustomSimpleToolbar deleteRedirect={redirectUrl} />}
      >
        <TextInput
          source='first_name'
          label={translate("profile.first_name")}
          required
        />

        <TextInput
          source='last_name'
          label={translate("profile.last_name")}
          required
        />

        <TextInput source='email' label={translate("profile.email")} required />

        <SelectArrayInput
          label={translate("users.roles")}
          source='roles'
          choices={roles}
        >
          <ChipField source='id' />
        </SelectArrayInput>

        <ReferenceInput
          source='organization_id'
          reference='organizations'
          sort={{ field: "name", order: "ASC" }}
          isRequired
        >
          <AutocompleteInput
            label={translate("organizations.singular_name")}
            disabled={!isAdmin}
          />
        </ReferenceInput>

        {!firstTime && (
          <FormControlLabel
            control={
              <Switch
                checked={showPassowordInput}
                defaultChecked={showPassowordInput}
                onChange={() => setShowPasswordInput(!showPassowordInput)}
              />
            }
            label={translate("users.change_password_question")}
          />
        )}

        {showPassowordInput && (
          <PasswordInput
            source='password'
            label={translate("login.password")}
            required={firstTime}
          />
        )}
      </SimpleForm>
    </Container>
  );
};

export default UserForm;
