import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useGetIdentity,
  useGetOne,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useEffect, useState } from "react";
import { hasAccess } from "../../global/permissions";
import LicenseListButton from "../Licenses/Buttons/LicenseListButton";
import CustomActionBar from "../Layout/CustomActionBar";
import SkeletonList from "../Loading/SkeletonList";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import ListLayout from "../Layout/ListLayout";
import { makeStyles } from "@mui/styles";
import { theme } from "../../global/styles";
import DatagridTitleField from "../Layout/Fields/DatagridTitleField";
import { IconButton, Tooltip } from "@mui/material";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { duplicateExperience } from "../../services/api/experiences.service";

const useStyles = makeStyles({
  disabledRow: {
    "& .RaDatagrid-row:not(.RaDatagrid-selectable)": {
      background: "rgba(0, 0, 0, 0.12)",
      cursor: "not-allowed",
    },
    "& .RaDatagrid-row:not(.RaDatagrid-selectable) .RaDatagrid-rowCell": {
      background: "transparent",
      borderRadius: 0,
      opacity: 0.5,
      pointerEvents: "none",
    },
    "& .RaDatagrid-row:not(.RaDatagrid-selectable):last-of-type": {
      borderRadius: "0px 0px 16px 16px",
    },
    "& .RaDatagrid-row:not(.RaDatagrid-selectable):last-of-type .RaDatagrid-rowCell:first-of-type":
    {
      borderRadius: "0px 0px 0px 16px",
    },
    "& .RaDatagrid-row:not(.RaDatagrid-selectable):last-of-type .RaDatagrid-rowCell:last-of-type":
    {
      borderRadius: "0px 0px 16px 0px",
    },
    "& .RaDatagrid-row:not(.RaDatagrid-selectable) .RaEditButton-root": {
      opacity: 0,
    },
  },
  booleanField: {
    "& .RaBooleanField-falseIcon": {
      color: theme.palette.error.main,
    },
    "& .RaBooleanField-trueIcon": {
      color: theme.palette.success.main,
    },
  },
});

const BulkActionButtons = ({ onSelectedIdsChange }) => {
  const { selectedIds } = useListContext();

  useEffect(() => {
    onSelectedIdsChange(selectedIds);
  }, [selectedIds, onSelectedIdsChange]);

  return <></>;
};

const ConditionalAdminEditButton = ({ id }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  return (
    <EditButton
      variant='contained'
      sx={
        record.organization_id !== id && !permissions.includes("admin")
          ? { opacity: 0 }
          : {}
      }
      icon={null}
    />
  );
};

const ExperienceList = ({
  simplifiedView,
  onSelectedIdsChange,
  standaloneOnly,
}: {
  simplifiedView?: boolean;
  onSelectedIdsChange?: any;
  standaloneOnly?: boolean;
}) => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const classes = useStyles();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const {
    data: organizationData,
  } = useGetOne("organizations", { id: identity?.organization?.id });
  const showUnivrsityOption =
    organizationData?.category_ids?.includes(107) ||
    permissions.includes("admin");
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (!organization || !permissions) setLoading(true);
    else setLoading(false);
  }, [organization, permissions]);

  if (!hasAccess({ name: "experiences", crudType: "list" }, permissions))
    redirect("/");

  let filterValues = !permissions.includes("admin")
    ? { organization_id: organization.id, standalone_only: standaloneOnly }
    : { standalone_only: standaloneOnly };

  const showLicenseButton =
    hasAccess({ name: "licenses", crudType: "list" }, permissions) &&
    !simplifiedView;
  
  const handleDuplicateClick = async (experienceId) => {
    try {
      await duplicateExperience(experienceId);
      refresh();
      notify(
        translate("experiences.duplicate_success"),
        { type: "success" }
      );
    } catch (error) {
      console.error("Error duplicating experience:", error);
      notify(
        translate("experiences.duplicate_error"),
        { type: "error" }
      );
    }
  };

  const list = !loading ? (
    <List
      resource={"experiences"}
      exporter={false}
      perPage={50}
      filters={<DefaultFilter />}
      sort={{ field: "updated_at", order: "DESC" }}
      filter={filterValues}
      actions={
        simplifiedView ? null : (
          <CustomActionBar
            createButtonProps={{ label: translate("experiences.create") }}
          />
        )
      }
      disableSyncWithLocation={simplifiedView}
    >
      <Datagrid
        bulkActionButtons={
          simplifiedView ? (
            <BulkActionButtons onSelectedIdsChange={onSelectedIdsChange} />
          ) : (
            false
          )
        }
        rowClick={false}
      >
        {permissions.includes("admin") && (
          <TextField source='id' label={translate("misc.id")} />
        )}
        <DatagridTitleField source='name' label={translate("misc.name")} />
        {!simplifiedView && (
          <ReferenceField
            label={translate("organizations.singular_name")}
            source='organization_id'
            reference='organizations'
            sx={{
              "& .MuiLink-underlineAlways": {
                textDecoration: "none",
              },
            }}
          >
            <FunctionField
              source='name'
              render={(record) => record.name.replace(/ /g, "\u00A0")}
            />
          </ReferenceField>
        )}
        {showUnivrsityOption && (
          <BooleanField
            className={classes.booleanField}
            source='included_to_univrsity'
            label={translate("experiences.shared_univrsity")}
          />
        )}
        <DateField
          source='updated_at'
          label={translate("misc.last_update")}
          showTime
        />
        {showLicenseButton && (
          <LicenseListButton parentResource='experiences' />
        )}
        {!simplifiedView && <ConditionalAdminEditButton id={organization.id} />}
        {permissions.includes("admin") && (
          <FunctionField
            render={(record) => (
              <Tooltip title={translate("experiences.duplicate")}>
                <IconButton
                  onClick={() => handleDuplicateClick(record.id)}
                >
                  <ControlPointDuplicateIcon />
                </IconButton>
              </Tooltip>
            )}
          />
        )}
      </Datagrid>
    </List>
  ) : (
    <SkeletonList />
  );

  if (simplifiedView) return list;

  return (
    <ListLayout title={translate("resources.my_experiences")}>
      {list}
    </ListLayout>
  );
};

ExperienceList.defaultProps = {
  simplifiedView: false,
  standaloneOnly: false,
};

export default ExperienceList;
