import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  BooleanField,
  ImageField,
  ReferenceArrayField,
  Show,
  TextField,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { theme } from "../../global/styles";
import EditIcon from "@mui/icons-material/Edit";
import OptionItem from "./OptionItem";
import React from "react";
import { hasAccess } from "../../global/permissions";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const Icon = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record || !record.logo?.public_display_link) return null;
  return (
    <ImageField
      source='logo.public_display_link'
      title={translate("misc.logo")}
      sx={{
        "& .RaImageField-image": {
          width: 100,
          height: 100,
          borderRadius: 1,
        },
      }}
    />
  );
};

const Name = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <Typography variant='h4'>{record.name}</Typography>;
};

const Options = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <Stack gap={3}>
      <OptionItem title={translate("tags.categories.index")}>
        {record?.category_ids?.length > 0 ? (
          <ReferenceArrayField
            reference='tags'
            source='category_ids'
            sx={{ width: "fit-content" }}
          />
        ) : (
          <Typography fontSize={"0.875rem"}>
            {translate("misc.undefined")}
          </Typography>
        )}
      </OptionItem>
      <OptionItem title={translate("misc.email_domain")}>
        <Stack direction='row' gap={1}>
          {record.email_domain && <AlternateEmailIcon color='primary' />}
          <TextField
            source='email_domain'
            emptyText={translate("misc.undefined")}
          />
        </Stack>
      </OptionItem>
      <OptionItem title={translate("misc.signup_enabled")}>
        <Stack direction='row' gap={1}>
          <BooleanField
            source='signup_enabled'
            color={
              record.signup_enabled
                ? theme.palette.success.main
                : theme.palette.error.main
            }
          />
        </Stack>
      </OptionItem>
      <OptionItem title={translate("organizations.experiences.images.default")}>
        <ImageField
          source='default_experience_image.public_display_link'
          emptyText={translate("misc.undefined")}
          sx={{
            "& .RaImageField-image": {
              width: 100,
              height: 100,
              fontSize: "1rem",
              borderRadius: 1,
            },
          }}
        />
      </OptionItem>
    </Stack>
  );
};

interface OverviewGridItemProps {
  title: string;
  value: string;
  href?: string;
}

const OverviewGridItem: React.FC<OverviewGridItemProps> = ({
  title,
  value,
  href,
}) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();

  return (
    <Grid item xs={12} md={6} xl>
      <Card sx={{ height: "100%" }}>
        <CardHeader
          title={title}
          titleTypographyProps={{
            variant: "subtitle2",
            color: theme.palette.grey[600],
          }}
        />
        <CardContent sx={{ textAlign: "center", mt: -1 }}>
          <Typography fontWeight={600} fontSize={28} color='primary.main'>
            {value}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          {!href.includes("licenses") || permissions?.includes("admin") ? (
            <Button
              size='small'
              variant='contained'
              endIcon={<ChevronRightIcon />}
              href={href}
            >
              {translate("misc.see_more")}
            </Button>
          ) : null}
        </CardActions>
      </Card>
    </Grid>
  );
};

const Overview = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <Grid container spacing={2}>
      <OverviewGridItem
        title={translate("resources.users")}
        value={record.user_count}
        href={`/organizations/${record.id}/users`}
      />
      <OverviewGridItem
        title={translate("resources.experiences")}
        value={record.licensed_experience_count}
        href={`/experiences`}
      />
      <OverviewGridItem
        title={translate("resources.licenses")}
        value={record.license_count}
        href={`/organizations/${record.id}/licenses`}
      />
      <OverviewGridItem
        title={translate("resources.service-licenses")}
        value={record.service_license_count}
        href={`/organizations/${record.id}/service-licenses`}
      />
    </Grid>
  );
};

const Title = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const canEdit = hasAccess(
    { name: "organizations", crudType: "edit" },
    permissions
  );

  if (!record) return null;
  return (
    <Stack
      direction={"row"}
      gap={2}
      alignItems={"center"}
      justifyContent={"space-between"}
      my={2}
    >
      <Box />
      <Typography variant='h5' textAlign='center'>
        {translate("organizations.singular_name")}
      </Typography>
      {canEdit ? (
        <IconButton
          onClick={() => redirect("edit", "organizations", record.id)}
        >
          <EditIcon />
        </IconButton>
      ) : (
        <Box />
      )}
    </Stack>
  );
};

const OrganizationShow = () => {
  return (
    <Container maxWidth='lg'>
      <Show
        actions={null}
        sx={{
          "& .RaShow-card": {
            background: "none",
            boxShadow: "none",
            border: "none",
          },
        }}
      >
        <Title />
        <Stack px={8} py={4} gap={8} component={Paper}>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={4}
          >
            <Icon />
            <Name />
          </Stack>
          <Options />
          <Overview />
        </Stack>
      </Show>
    </Container>
  );
};

export default OrganizationShow;
