import { createTheme } from "@mui/material";
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import { keyframes } from "@emotion/react";

const anim = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    50% {
        opacity: 0.5;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const palette = {
  background: {
    default: "#F1F8FF",
    light: "#FCFDFF",
  },
  primary: {
    main: "#0C9AF2",
    light: "#abdbff",
    dark: "#0171b0",
    darker: "#004b77",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#6ec6ff",
    main: "#2196f3",
    dark: "#0069c0",
    contrastText: "#fff",
  },
  error: {
    main: "#f44336",
    light: "#e57373",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  purple: {
    main: "#951C7D",
  },
  orange: {
    main: "#E1701B",
  },
  customGrey: {
    main: "#515B69",
    dark: "#4a4a4a",
  },
  success: {
    main: "#4caf50",
    light: "#81c784",
    dark: "#388e3c",
    contrastText: "#fff",
  },
  warning: {
    main: "#ff9800",
    light: "#ffb74d",
    dark: "#f57c00",
    contrastText: "#fff",
  },
};

const glassSx = {
    // background: "rgba(255, 255, 255, 0.5)",
    // borderRadius: "16px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.15)",
    // backdropFilter: "blur(8px)",
    // "-webkit-backdrop-filter": "blur(8px)",
    background: "rgba(255, 255, 255, 1)",
};

const _theme = createTheme({
  palette: {
    ...palette,
  },
  spacing: 8,
  shape: { borderRadius: 16 },
  typography: {
    fontFamily: "Montserrat",
    h1: {
      fontWeight: 500,
      animation: `${anim} .3s ease-in-out`,
    },
    h2: {
      fontWeight: 500,
      animation: `${anim} .3s ease-in-out`,
    },
    h3: {
      fontWeight: 500,
      animation: `${anim} .3s ease-in-out`,
    },
    h4: {
      fontWeight: 500,
      animation: `${anim} .3s ease-in-out`,
    },
    h5: {
      fontWeight: 500,
      animation: `${anim} .3s ease-in-out`,
    },
    h6: {
      fontWeight: 500,
      animation: `${anim} .3s ease-in-out`,
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    },
    caption: {
      fontWeight: 500,
    },
    overline: {
      fontWeight: 500,
    },
    subtitle2: {},
    subtitle1: {},
    body2: {},
    allVariants: {
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
        margin: "normal" as const,
        size: "medium" as const,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
        margin: "normal" as const,
        size: "medium" as const,
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          borderRadius: "24px",
          "&:hover": {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.dark,
          },
          "& .MuiButton-endIcon:has(svg[data-testid=ChevronRightIcon])": {
            marginRight: "-5px",
            marginLeft: "4px",
          },
          "& .MuiButton-endIcon:has(svg[data-testid=ChevronRightIcon]) svg": {
            marginLeft: '-1rem',
            opacity: 0,
            transition: "all 0.25s ease-in-out",
          },
          "&:hover .MuiButton-endIcon:has(svg[data-testid=ChevronRightIcon]) svg": {
            marginLeft: '-0.2rem',
            opacity: 1,
          }
        },
        textPrimary: {
          "&:hover": {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.dark,
          },
        },
        containedError: {
          color: "white !important",
          "&:hover": {
            backgroundColor: "darkred !important",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.main,
          },
          "&.Mui-selected": {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          animation: `${anim} .3s ease-in-out`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          animation: "none",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "white !important",
        },
        active: {
          color: "white !important",
        },
        icon: {
          color: "white !important",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        displayedRows: {
          margin: 0,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:last-child .MuiTableCell-root:first-child": {
            borderRadius: "0px 0px 0px 16px",
          },
          "& .MuiTableRow-root:last-child .MuiTableCell-root:last-child": {
            borderRadius: "0px 0px 16px 0px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          "&.MuiTableRow-hover:hover": {
            backgroundColor: palette.background.default,
          },
          "&:nth-child(even)": {
            backgroundColor: palette.background.light,
          },
          "&.MuiTableRow-hover:nth-child(even):hover": {
            backgroundColor: palette.background.light,
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "green",
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          "&.Mui-completed": {
            color: "green",
            // fontWeight: "bold",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        docked: {
          "&.MuiDrawer-docked": {
            height: "unset",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          border: "none",
          "&.Mui-selected": {
            backgroundColor: palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: palette.primary.dark,
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
        grouped: {
          borderRadius: "16px !important",
          border: "none",
          margin: "0 !important",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit !important",
        },
      },
    },
  },
});

export const theme: any = {
  ..._theme,
  components: {
    ..._theme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          borderRadius: "16px",
          "& .RaDatagrid-headerCell": {
            backgroundColor: palette.primary.main,
            color: "white !important",
            // fontWeight: "bold",
            borderRadius: "0",
            "&:first-child": {
              borderRadius: "16px 0px 0px 16px",
            },
            "&:last-child": {
              borderRadius: "0 16px 16px 0",
            },
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          background: palette.background.default,
          paddingTop: "1em",
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.main,
          },
          "&.RaMenuItemLink-active": {
            color: 'rgba(0, 0, 0, 0.6)',
            "& .RaMenuItemLink-icon": {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          },
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          "& .RaBulkActionsToolbar-toolbar": {
            padding: "0",
          },
        },
      },
    },
  },
};
